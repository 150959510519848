import router from '@/router';
import { computed, defineComponent, onMounted, reactive, ref, } from 'vue';
import BaseTable from '@/components/BaseTable/index.vue';
import BaseTableCol from '@/components/BaseTable/BaseTableCol';
import Pager from '@/components/Pager.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import InputField from '@/components/InputField.vue';
import { useI18n } from 'vue-i18n';
import Backend from '@/core/Services/Backend';
// import { useDeleteDuplicate } from '@/utils/useDuplicate';
import BaseButton from '@/components/BaseButton.vue';
import BaseRadio from '@/components/BaseRadio.vue';
import useDialog from '@/utils/useDialog';
import Dialog from '@/components/Dialog.vue';
import AuthQr from '@/components/AuthQr/index.vue';
import { getFixedFloor, getSum } from '@/core/utils/number';
// import { validateEthLikeAddress } from '@/core/utils/validate';
import { ChainAndCoin } from '@/core/types/enums';
import Toast from '@/components/Toast';
import ButtonLayer from '@/components/ButtonLayer.vue';
import getApproveGas from '@/core/Services/Backend/getApproveGas';
import { openExplorerFortx } from '@/core/utils/openExplorer';
import useLocalPager from '@/utils/useLocalPager';
import pollApproveStatus from '@/utils/pollApproveStatus';
import ContractAddressVue from '@/components/ContractAddress.vue';
import { getGasAmount } from '@/core/utils/count';
import ContractQr from './ContractQr.vue';
export default defineComponent({
    name: 'NftSetGas',
    components: {
        BaseTable,
        BaseTableCol,
        Pager,
        BaseSwitch,
        InputField,
        BaseButton,
        BaseRadio,
        Dialog,
        AuthQr,
        ContractQr,
        ButtonLayer,
        ContractAddressVue,
    },
    setup() {
        const { t } = useI18n();
        // 先判断下编辑了地址列表没
        const sessionDropData = sessionStorage.getItem('nftSet');
        if (sessionDropData === null) {
            router.push('inc/nft');
        }
        const dropData = JSON.parse(sessionDropData);
        // 1.验证是否已经授权 2.授权 3.等待授权 4.设置gas 5.完成
        // 1的验证结果是已授权就跳过2和3
        const step = ref(1);
        // 空投地址数
        const addressCount = dropData.addressList.length;
        // 转账笔数
        // const transferCount = dropData.addressList.length;
        // 空投nft总数
        const dropTokenCount = computed(() => {
            if (dropData.standard === '721') {
                return dropData.addressList.length;
            }
            return getSum(dropData.addressList.map((item) => item.count));
        });
        // 默认授权额度选项
        const authorizationLimit = ref('sendCount');
        // 获取授权用的gas ，不修改在授权接口再传回去
        const approveGasPrice = ref('');
        const approveGasLimit = ref('');
        const approveGasFee = ref('');
        const getApproveGasFun = () => {
            getApproveGas(dropData.tokenHash, dropTokenCount.value, dropData.chain, true).then((res) => {
                approveGasPrice.value = res.gasPrice;
                approveGasLimit.value = res.gasLimit;
                approveGasFee.value = res.gasFee;
            });
        };
        onMounted(() => {
            getApproveGasFun();
        });
        const { isVisible: isAuthVisible, showDialog: showAuthDialog, closeDialog: closeAuthDialog, } = useDialog();
        const approveId = ref('');
        const approveHash = ref('');
        // 获取交易gasLimit
        // coin直接执行，token在approve的回调中获取id再执行
        const gasLimit = ref(0);
        const getGasLimit = () => {
            Backend.getNftGasLimit(dropData.chain, dropData.tokenHash, dropData.standard, dropData.addressList.map((item) => item.address), dropData.addressList.map((item) => item.count), dropData.addressList.map((item) => item.tokenId)).then((res) => {
                gasLimit.value = res.result;
            });
        };
        // 验证是否已授权
        const verifyApprove = () => {
            Backend.verifyApprove(dropData.chain, dropData.tokenHash).then((res) => {
                // 已授权就不用再去授权了
                if (res.result === true) {
                    step.value = 4;
                    getGasLimit();
                }
                else {
                    step.value = 2;
                }
            });
        };
        onMounted(() => {
            verifyApprove();
        });
        // 授权成功回调(还要等待授权)
        const authlSuccessHandler = (hash, chainType, qrId) => {
            approveHash.value = hash;
            approveId.value = qrId;
            closeAuthDialog();
            step.value = 3;
            pollApproveStatus(chainType, hash).then((res) => {
                if (res === '1') {
                    Toast(t('authorization_success'));
                    step.value = 4;
                    getGasLimit();
                }
                if (res === '0') {
                    Toast(t('authorization_fails'));
                    step.value = 2;
                    getApproveGasFun();
                }
            });
        };
        // 空投列表表格
        const { pageNumber, currentArray, total } = useLocalPager(dropData.addressList);
        // 展示选择框或输入框
        const isUserDefined = ref(false);
        const gasOracle = ref({
            safeLow: 0,
            average: 0,
            fast: 0,
            fastest: 0,
        });
        const gasPriceSteps = reactive([
            {
                label: computed(() => `${t('low')}(<30min)`),
                value: 0,
                speed: 'low',
            },
            {
                label: computed(() => `${t('average')}(<5min)`),
                value: 0,
                speed: 'average',
            },
            {
                label: computed(() => `${t('high')}(<2min)`),
                value: 0,
                speed: 'high',
            },
        ]);
        const selectSpeed = ref('average');
        const selectValue = computed(() => {
            const checked = gasPriceSteps.find((item) => item.speed === selectSpeed.value);
            return checked?.value;
        });
        Backend.getGasOracle(dropData.chain).then((res) => {
            gasOracle.value = {
                fast: Number(res.data.Result.fast / 10),
                safeLow: Number(res.data.Result.safeLow / 10),
                average: Number(res.data.Result.average / 10),
                fastest: Number(res.data.Result.fastest / 10),
            };
            gasPriceSteps[0].value = res.data.Result.safeLow / 10; // 接口返回是gwei * 10
            gasPriceSteps[1].value = res.data.Result.average / 10;
            gasPriceSteps[2].value = res.data.Result.fast / 10;
        });
        // 自定义的gasPrice和gasLimit
        const gasPriceValue = ref('');
        const gasLimitValue = ref('');
        // 手续费
        const gas = computed(() => {
            if (!isUserDefined.value) {
                // 选择框模式
                return getGasAmount(String(selectValue.value), String(gasLimit.value));
            }
            // 自定义模式
            return getGasAmount(String(gasPriceValue.value), String(gasLimitValue.value));
        });
        const tableState = reactive({
            page: 1,
            total: Math.ceil(dropData.addressList.length / 10),
        });
        const { isVisible: isShowQrContract, closeDialog: closeQrContract, showDialog: showQrContract } = useDialog();
        const currentCoin = computed(() => {
            switch (dropData.chain) {
                case 'eth':
                    return ChainAndCoin.eth;
                case 'polygon':
                    return ChainAndCoin.polygon;
                case 'bsc':
                    return ChainAndCoin.bsc;
                case 'okex':
                    return ChainAndCoin.okex;
                default:
                    throw Error('not find for chainType');
            }
        });
        const transactionHash = ref('');
        const sendSuccessfully = (hash) => {
            closeQrContract();
            Toast(t('send_successfully'));
            step.value = 5;
            transactionHash.value = hash;
        };
        return {
            t,
            step,
            authorizationLimit,
            dropData,
            dropTokenCount,
            tableState,
            isUserDefined,
            selectSpeed,
            selectValue,
            gasPriceSteps,
            gasPriceValue,
            gasLimitValue,
            // transferCount,
            gasOracle,
            gas,
            addressCount,
            isAuthVisible,
            showAuthDialog,
            closeAuthDialog,
            authlSuccessHandler,
            isShowQrContract,
            closeQrContract,
            showQrContract,
            gasLimit,
            getFixedFloor,
            currentCoin,
            sendSuccessfully,
            approveGasFee,
            transactionHash,
            openExplorerFortx,
            pageNumber,
            currentArray,
            total,
            approveHash,
            approveGasPrice,
            approveGasLimit,
        };
    },
});
